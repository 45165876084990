import ArrowRight from '../assets/arrow-right.svg';
import SharegatePartners from '../assets/sharegate-partners.png';
import Webinars from '../assets/webinars.png';
import AllFeatures from '../assets/all-features.png';

export const CTAs = ( ) => {

    return <div className="custom-columns">
        <div className="custom-column card">
            <img className="column-img" src={SharegatePartners} alt="Sharegate Partners" />
            <h3>Become a ShareGate&nbsp;partner</h3>
            <div className="button-container">
                <a className="button outline-button" href="https://sharegate.com/partner?utm_source=whatismytenantid&utm_campaign=whatismytenantid&utm_medium=bloc&utm_content=partner" rel="noreferrer" target="_blank">Get in touch&nbsp;<img className="arrow-right" src={ArrowRight} alt="arrow right" /></a>
            </div>
        </div>
        <div className="custom-column card">
            <img className="column-img" src={Webinars} alt="Webinars" />
            <h3>IT resources for&nbsp;M365</h3>
            <div className="button-container">
                <a className="button outline-button" href="https://sharegate.com/blog?utm_source=whatismytenantid&utm_campaign=whatismytenantid&utm_medium=bloc&utm_content=ressources" rel="noreferrer" target="_blank">Get resources&nbsp;<img className="arrow-right" src={ArrowRight} alt="arrow right" /></a>
            </div>
        </div>
        <div className="custom-column card">
            <img className="column-img" src={AllFeatures} alt="All features" />
            <h3>Learn about ShareGate</h3>
            <div className="button-container">
                <a className="button outline-button" href="https://sharegate.com/all-features?utm_source=whatismytenantid&utm_campaign=whatismytenantid&utm_medium=bloc&utm_content=features" rel="noreferrer" target="_blank">Explore all features&nbsp;<img className="arrow-right" src={ArrowRight} alt="arrow right" /></a>
            </div>
        </div>
    </div>
}